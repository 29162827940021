<template>
  <v-container grid-list-xs >
    <v-layout wrap row v-show="!edit">
      <v-flex xs12 sm6>
        <div
          v-show="$refs.upload && $refs.upload.dropActive"
          class="drop-active"
        >
          <h4>Drop files to upload</h4>
        </div>
        <div class="avatar-upload">
          <div class="text-center p-2">
            <label for="avatar">
              <img
                :src="
                  files.length
                    ? files[0].url
                    : this.placeholder
                "
                :class="imageClass"
              />
              <h4 class="pt-2" v-if="edit">Upload or Drop files or take picture</h4>
            </label>
          </div>

          <div class="text-center p-2" >
            <file-upload
              extensions="gif,jpg,jpeg,png,webp"
              accept="image/png,image/gif,image/jpeg,image/webp"
              name="avatar"
              class="mt-3 py-2 v-btn theme--light primary"
              :drop="!edit"
              v-model="files"
              @input-filter="inputFilter"
              @input-file="inputFile"
              ref="upload"
            >
              Select
            </file-upload>
          </div>
        </div>
      </v-flex>


    </v-layout>

    <v-layout wrap row v-if="files.length > 0 && !edit">
      <v-flex xs12 sm6>
        <v-btn color="success" @click.prevent="upload">Upload</v-btn>
        <v-btn text @click.prevent="$refs.upload.clear">Cancel</v-btn>
      </v-flex>
      <v-flex xs10>
        <v-alert :value="true" color="info" icon="info" outlined>
          It may take few minutes to update your avatar to all the
          settings!</v-alert
        >
      </v-flex>
    </v-layout>
    <v-layout row wrap v-show="files.length && edit">
      <v-flex xs12 class="avatar-edit" v-if="files.length">
        <img ref="editImage" height="400" :src="files[0].url" />

        <div class="text-center p-4">
          <v-btn color="success" @click.prevent="editSave">Crop</v-btn>
          <v-btn text @click.prevent="$refs.upload.clear">Cancel</v-btn>
        </div>
      </v-flex>
    </v-layout>

  </v-container>
</template>

<script>
import Cropper from 'cropperjs'
import FileUpload from 'vue-upload-component'
import 'cropperjs/dist/cropper.min.css'
import { mapGetters } from 'vuex'

export default {
  components: {
    FileUpload
  },
  props:{
    imageClass: {
      type: String
    },
    aspectRatio: {
      type: Number
    },
    viewMode:{
      type: Number
    },
    placeholder:{
      type: String,
      default: '/svg/camera.svg'
      //default: 'https://storage.googleapis.com/img.fod.live/static/img/none.png'
    }
  },

  data() {
    return {
      files: [],
      edit: false,
      cropper: false
    }
  },
  computed: {
    ...mapGetters({
    })
  },
  watch: {
    edit(value) {
      
      if (value) {
        this.$nextTick(function() {
          if (!this.$refs.editImage) {
            return
          }

          let cropper = new Cropper(this.$refs.editImage, {
            aspectRatio: this.aspectRatio?this.aspectRatio:null,
            viewMode: this.viewMode||0,
          })
          this.cropper = cropper
        })
      } else {
        if (this.cropper) {
          this.cropper.destroy()
          this.cropper = false
        }
      }
    }
  },

  methods: {
    editSave() {
      this.edit = false

      let oldFile = this.files[0]

      let binStr = atob(
        this.cropper
          .getCroppedCanvas()
          .toDataURL(oldFile.type)
          .split(',')[1]
      )
      let arr = new Uint8Array(binStr.length)
      for (let i = 0; i < binStr.length; i++) {
        arr[i] = binStr.charCodeAt(i)
      }

      let file = new File([arr], oldFile.name, { type: oldFile.type })

      this.$refs.upload.update(oldFile.id, {
        file,
        type: file.type,
        size: file.size,
        active: true
      })
    },

    alert(message) {
      alert(message)
    },

    inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        this.$nextTick(function() {
          this.edit = true
        })
      }
      if (!newFile && oldFile) {
        this.edit = false
      }
    },

    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
          this.alert('Your choice is not a picture')
          return prevent()
        }
      }

      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = ''
        let URL = window.URL || window.webkitURL
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file)
        }
      }
    },
    upload() {
      this.edit = false
      this.uploadProgress = true
      
      this.$emit('imageUpload', this.files[0])

    }
  }
}
</script>
<style scoped>
.rounded-circle {
  width: 200px;
  height: 200px;
  border-radius: 50%;
}
.box {
  width: 200px;
  height: 200px;

}
.text-center .btn {
  margin: 0 0.5rem;
}
.avatar-edit-image {
  max-width: 100%;
}

.drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: 0.6;
  text-align: center;
  background: #000;
}
.cropper-crop-box {
  border-radius: 50%;
}
.cropper-view-box,
.cropper-face {
  border-radius: 50%;
}
.drop-active h4 {
  margin: -0.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  border-radius: 50%;
  padding: 0;
}
</style>
